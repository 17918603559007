import {Component, Vue} from 'vue-property-decorator';
import {Evaluation} from "../../shared/model/Evaluation";
import {EvaluationType} from "../../shared/model/EvaluationType";
import {ProviderType} from "../../shared/model/ProviderType";
import {ActivityType} from "../../shared/model/ActivityType";
import {County} from "../../shared/model/County";
import {SurveyType} from "../../shared/model/SurveyType";
import {Status} from "../../shared/model/Status";
import {evalApi} from "../../plugins/axios";
import moment from "moment";
import {FiscalYear} from "../../shared/model/FiscalYear";
import {Department} from "../../shared/model/Department";
import {Flag} from "@/shared/model/Flag";
import {FundingType} from "@/shared/model/FundingType";
import {rules} from "@/shared/model/Rules";

@Component({
    components: {},
})
export default class FundingTypeForm extends Vue {
    title = "FundingType Form"
    maskText = "";
    valid = true;
    fundingType: FundingType = new FundingType();
    menu1 = false;
    evaluationTypes = EvaluationType.values;
    providerTypes = ProviderType.values;
    activityTypes = ActivityType.values;
    counties = County.values;
    surveyTypes: Array<String> = [];
    statuses = Status.values;
    flags = ["Y","N"];
    fiscalYears = new FiscalYear().values;
    endDateString: string = "";
    dueDateString: string = "";
    departments = Department.values.sort();
    requiredRule = rules;
    snackbar = {
        visible: false,
        color: "success",
        text: ""
    };

    formatDate(date: String): String {
        if (!date) return "MM/DD/YYYY";
        const [year, month, day] = date.split('-')
        return `${month}/${day}/${year}`
    }

    async submitNewFundingType(): Promise<void> {
        const res = await evalApi.get(`/fundingType/exists/` + this.fundingType.fundingTypeId)
        if (res.data) {
            this.snackbar.color = "error"
            this.snackbar.text = "Funding Type ID '" + this.fundingType.fundingTypeId + "' already exists. No duplicates are allowed."
            this.snackbar.visible = true
            return
        }
        evalApi.post("/fundingType/create", {
            fundingTypeId: this.fundingType.fundingTypeId,
            fundingType: this.fundingType.fundingType,
            stateFlag: this.fundingType.stateFlag,
            stateOnlyFlag: this.fundingType.stateOnlyFlag,
            insertuid: "hrdtimetracking_owner",
        }).then(res => {
            this.snackbar.color = "success"
            this.snackbar.text = "Funding Type " + this.fundingType.fundingTypeId + " has been created."
            this.snackbar.visible = true
        }).catch(error => {
            this.snackbar.color = "error"
            this.snackbar.text = "An error occurred creating the fundingType: >>>> " + error
            this.snackbar.visible = true
        })
    }
}