import { render, staticRenderFns } from "./funding-type-form.vue?vue&type=template&id=1b80fb2e"
import script from "./funding-type-form.component.ts?vue&type=script&lang=ts&external"
export * from "./funding-type-form.component.ts?vue&type=script&lang=ts&external"
import style0 from "./funding-type-form.vue?vue&type=style&index=0&id=1b80fb2e&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports